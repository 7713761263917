import './index.scss'

window.copyToClipboard = async function (buttonEl) {
  const textToCopy = buttonEl.dataset.clipboardContent

  const onSuccessfulCopy = () => {
    buttonEl.classList.add('copied')

    setTimeout(() => {
      buttonEl.classList.remove('copied')
    }, 2000)
  }

  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy)
    onSuccessfulCopy()
  } else {
    const textarea = document.createElement('textarea')
    textarea.value = textToCopy

    // Move the textarea outside the viewport to make it invisible
    textarea.style.position = 'absolute'
    textarea.style.left = '-99999999px'

    document.body.prepend(textarea)

    // highlight the content of the textarea element
    textarea.select()

    try {
      document.execCommand('copy')
    } catch (err) {
      console.log(err)
    } finally {
      textarea.remove()
      onSuccessfulCopy()
    }
  }
}
